import React, { Component } from "react";
import { FaGithub, FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaGithub /> , link: 'https://www.github.com/b0bbybaldi'},
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/b0bbybaldi'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/b0bbybaldi'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/b0bbybaldi'},
    {Social: <FaTwitter /> , link: 'https://www.twitter.com/b0bbybaldi'},
]

class AboutTwo extends Component{
    render(){
        let title = 'About',
        description = 'Silibrain LLC is a technology solutions company founded in 2018 that endeavor in bettering human life and push global progress forward through the use of technology. Silibrain has the mission of making the latest technological advances available to millions by providing high-caliber, more affordable solutions to all. To make such a dream possible, the company focuses on designing electronics and software to meet and exceed client expectations. Software development and business, engineering, and technology consulting services are also done at the highest level.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who are we?</h3>
                                                <p>Problem solvers, scientists, technologists, engineers  & entrepreneurs at heart.</p>
                                                <p>Endeavouring in making the latest technological advances available to more to enhance life by addressing real world problems.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Get to know us</h3>
                                                <p>Roberto Baldizon, Founder @ Silibrain LLC.</p>
                                                <p>Experienced in designing, developing, and implementing technology systems.</p>
                                                <p> With a strong background in science, engineering, and business.</p>
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                                    <div className="inner text-center">
                                                        <ul className="social-share rn-lg-size d-flex justify-content-right liststyle">
                                                            {SocialShare.map((val , i) => (
                                                                <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;