import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">IN COLLABORATION WITH</div>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Monday Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Oracle for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Google for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Airtable for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="MongoDB for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Segment for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="Sinfonia Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="AWS Activate Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="Hospital Privado de Peten Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-10.png" alt="Rutgers Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-11.png" alt="Notion for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-12.png" alt="F6S Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-13.png" alt="Luszol Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-14.png" alt="Schneider Exchange Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-15.png" alt="Nvidia Inception Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-16.png" alt="GAN Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-17.png" alt="Pledge 1% Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-18.png" alt="McGill Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-19.png" alt="Twillio for Startups Member"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-20.png" alt="University of Toronto Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-21.png" alt="USC Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-22.png" alt="Diagnosticos Medicos Partner"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-23.png" alt="Riipen Partner"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;