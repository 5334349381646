const BlogContent = [
    {
        images: '01',
        title: 'Leveraged Expertise against Pandemics',
        category: 'As featured by Hunniwell Ventures, Silibrain helped fight COV-19 by building an EHR solution for temporary clinics as part of an IBM Initiative, and by aiding in development of an app to improve test kits for MIT\'s Hackathon.'
    },
    {
        images: '02',
        title: 'Hackathon Judge',
        category: 'In 2020 Silibrain\'s Founder Roberto Baldizon, participated as a First Line Judge for  a Hackathon organised by the UN, SAP, and IBM.'
    },
    {
        images: '03',
        title: 'Top 15 Healthcare App in 2019',
        category: 'In 2019 Silibrain, participated in Bayer and Roche\'s Global App Competition, finishing among the top 15, and pitching Incognimed at Medica in Düsseldorf, Germany.'
    },
    {
        images: '04',
        title: 'Partnered with Siemens for CT-Scans',
        category: 'In 2019, Silibrain, Siemens & Hospital Privado Staff (Prof. Dr. Wolfgang Wüst in picture) presented one of the most modern CT-Scans in the world, installed in Peten, Guatemala.'
    },
    {
        images: '05',
        title: 'Pitching Non-profit Iniative',
        category: 'In 2018, Silibrain\'s Founder Roberto Baldizon, and friends (Alexandra Goodwin in Picture) pitched MD-Anonymous at one of Berkeley\'s Chamber of Commerce Events for local tech initiatives in Berkeley, CA.'
    },
    {
        images: '06',
        title: 'Launching Non-profit Initiative',
        category: 'In 2018, Silibrain\'s Founder Roberto Baldizon, and friends (Alexandra Goodwin and Eric Ng in Picture) launched MD-Anonymous at UC Berkeley\'s Startup Project Demo Day at Impact Hub in Oakland, CA.'
    },
]

export default BlogContent;