import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-2',
        category: 'FookFake.news',
        title: ' A search engine for reliable news only.'
    },
    {
        image: 'image-3',
        category: 'Busted',
        title: ' A friendly app to scan for potential contagious disease diagnostics.'
    },
    {
        image: 'image-4',
        category: 'Saltzing',
        title: ' Consulting a scientific research venture to sterilize fabrics and surfaces using salt.'
    }
]

class Portfolio extends Component{
    render(){
        let title = 'Things we have done',
        description = 'We have vast experience developing, managing and consulting for new, and existing software and app development projects, as well as venture consulting involving how to best use technology. ';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4>{value.title}</h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href="mailto:roberto.baldizon@silibrain.com">Get More Info</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;